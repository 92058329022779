import CASTokenStrategy from './casTokenStrategy';
import ArtTokenStrategy from './artTokenStategy';
import KlickauthTokenStrategy from './klickauthTokenStrategy';
import ColaboratorTokenStrategy from './colaboratorTokenStrategy';

export default class TokenStrategyFactory {
  static getTokenStrategy(tokenKind) {
    switch (tokenKind) {
      case 'cas':
        return new CASTokenStrategy();
      case 'art':
        return new ArtTokenStrategy();
      case 'colaborator':
        return new ColaboratorTokenStrategy();
      case 'klickauth':
        return new KlickauthTokenStrategy();
      default:
        throw new Error('invalid token kind');
    }
  }
}
