class AbstractTokenStrategy {
  constructor(key) {
    this.key = key;
  }

  getToken() {
    return localStorage.getItem(this.key);
  }

  setToken(token) {
    localStorage.setItem(this.key, token);
  }

  removeToken() {
    localStorage.removeItem(this.key);
  }
}

export default AbstractTokenStrategy;
