import Cookies from 'js-cookie';
import AbstractTokenStrategy from './abstractTokenStrategy';

class ColaboratorTokenStrategy extends AbstractTokenStrategy {
  constructor() {
    super('integrationBWCentral');

    this.cookieApi = Cookies.withConverter({
      read: (value, name) => {
        if (name === 'integrationBWCentral') {
          return value;
        }

        return Cookies.converter.read(value, name);
      },
    });
  }

  getToken() {
    return this.cookieApi.get(this.key);
  }

  setToken(token) {
    this.cookieApi.set(this.key, token);
  }

  removeToken() {
    this.cookieApi.remove(this.key);
  }
}

export default ColaboratorTokenStrategy;
