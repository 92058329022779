import AbstractTokenStrategy from './abstractTokenStrategy';
import { surrogateEnabled } from '../cas_service';

class CasTokenStrategy extends AbstractTokenStrategy {
  constructor() {
    const key = surrogateEnabled() ? 'surrogate-id-token' : 'cas-id-token';
    super(key);
  }
}

export default CasTokenStrategy;
