import AuthService, { Bootstrap } from '@hotmart/cas-js';
import { PossibleStorages } from '@hotmart/cas-js/dist/auth.service';
import TokenStrategyFactory from '@/services/auth/tokenStrategies/tokenStrategyFactory';

const surrogateEnabled = () => sessionStorage.getItem('surrogate-enabled') === 'true';

const CAS_USER_STORE = surrogateEnabled() ? PossibleStorages.sessionStorage : PossibleStorages.localStorage;

const CAS_AUTHORITY = surrogateEnabled() ? process.env.VUE_APP_CAS_SURROGATE_URL : process.env.VUE_APP_CAS_URL;

const casEnabled = () => process.env.VUE_APP_CAS_AUTHENTICATION_ENABLED === 'true';


const isColaborator = () => {
  const colaboratorToken = TokenStrategyFactory.getTokenStrategy('colaborator').getToken();
  return colaboratorToken !== undefined && colaboratorToken !== null;
};

const authService = new AuthService({
  env: process.env.NODE_ENV === 'production' ? 'production' : 'development',
  client_id: process.env.VUE_APP_AUTH_CLIENT_ID,
  authority: `${CAS_AUTHORITY}/oidc`,
  storeToUse: CAS_USER_STORE,
  scope: 'openid profile email authorities user',
});

const bootstrapConfig = { appName: 'saas-send-client' };
const bootstrap = new Bootstrap(authService, bootstrapConfig);

export {
  bootstrap, casEnabled, surrogateEnabled, isColaborator,
};
export default authService;
